import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'

const app = createApp(App);

const isMobileDevice = ()=> {
  const ua = navigator.userAgent.toLowerCase();
  const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    ua
  );
  const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
  return t1 || t2;
};
app.config.globalProperties.$isMobile = isMobileDevice();
app.config.globalProperties.$winW = window.innerWidth;
app.config.globalProperties.$winH = window.innerHeight;
app.config.globalProperties.$winH = window.innerHeight;
app.config.globalProperties.$baseUrl = process.env.NODE_ENV== 'development' ? 'http://testmain.gxidt.cn/test-api' : 'https://gxidt.cn/product-api';

import {textOverflow} from './utils/textOverflow.js';
app.directive('text-overflow',textOverflow);

app.use(router);
app.mount('#app');
