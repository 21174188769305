import { // ref,
reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'contact-',
  components: {
    Header,
    Footer
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      current: 4
    });
    onMounted(() => {});

    const callback = val => {
      state.current = val;
    };

    return {
      proxy,
      ...toRefs(state),
      callback
    };
  }

};