import axios from 'axios'; // import dayjs from 'dayjs';

import { // ref,
reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'recruit-',
  components: {
    Header,
    Footer
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      current: 3,
      jobs: []
    });
    onMounted(() => {
      axios.post(`${proxy.$baseUrl}/recruitment/listCompanyRecruitment`, {
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.data.code === 200) {
          state.jobs = res.data.rows;
        }
      });
    });

    const callback = val => {
      state.current = val;
    };

    return {
      proxy,
      ...toRefs(state),
      callback
    };
  }

};