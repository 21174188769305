import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "container-wrap"
};
const _hoisted_3 = {
  class: "col jc-start ai-center"
};
const _hoisted_4 = {
  class: "new-title"
};
const _hoisted_5 = {
  class: "time"
};
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
    current: _ctx.current,
    onCallback: $setup.callback
  }, null, 8, ["current", "onCallback"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.newDetail.title), 1), _createElementVNode("p", _hoisted_5, "发布时间：" + _toDisplayString(_ctx.newDetail.publishTime) + " 作者：" + _toDisplayString(_ctx.newDetail.author), 1), _createElementVNode("div", {
    innerHTML: _ctx.newDetail.content,
    class: "content"
  }, null, 8, _hoisted_6)])]), _createVNode(_component_Footer)]);
}