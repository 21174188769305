import axios from 'axios';
import { // ref,
reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
const XX = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN'];
export default {
  name: 'bussiness-',
  components: {
    Header,
    Footer
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      current: 2,
      businessInfo: []
    });
    onMounted(() => {
      getBusiness();
    });

    const getBusiness = () => {
      axios.post(`${proxy.$baseUrl}/main/bussiness/listCompanyMainBussiness`, {}, {
        timeout: 10000
      }).then(res => {
        if (res.data.code == 200) {
          state.businessInfo = res.data.data;
        }
      });
    };

    const callback = val => {
      state.current = val;
    };

    const goTo = url => {
      window.location.href = url;
    };

    return {
      XX,
      proxy,
      ...toRefs(state),
      callback,
      goTo
    };
  }

};