import { reactive, toRefs, getCurrentInstance, defineComponent } from 'vue';
export default defineComponent({
  name: 'aFooter',
  emits: [],
  props: {// current: {
    //   type: Number,
    //   default: 0
    // }
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({});
    return {
      proxy,
      ...toRefs(state)
    };
  }

});