import axios from 'axios';
import dayjs from 'dayjs';
import { // ref,
reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'newsDetail',
  components: {
    Header,
    Footer
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      current: 1,
      newDetail: {}
    });
    onMounted(() => {
      let id = proxy.$route.query.id;

      if (id) {
        axios.get(`${proxy.$baseUrl}/news/getCompanyNewsById/${id}`).then(res => {
          if (res.data.code === 200) {
            res.data.data.publishTime = dayjs(res.data.data.publishTime).format('YYYY-MM-DD');
            state.newDetail = res.data.data;
          }
        });
      }
    });

    const callback = val => {
      state.current = val;
    };

    return {
      proxy,
      ...toRefs(state),
      callback
    };
  }

};