import axios from 'axios';
import dayjs from 'dayjs';
import { // ref,
reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import NewsPng from '@/assets/news.png';
export default {
  name: 'new-',
  components: {
    Header,
    Footer
  },

  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      current: 1,
      swiper: 1,
      newsLev1: {},
      newsLev2: [],
      newsLev3: []
    });
    onMounted(() => {
      // console.log(proxy.$winW)
      getNews();
    });

    const getNews = () => {
      axios.post(`${proxy.$baseUrl}/news/listCompanyNews`, {
        pageNum: 1,
        pageSize: 1000
      }, {
        timeout: 10000
      }).then(res => {
        if (res.data.code == 200) {
          let data = res.data.rows;

          if (data.length !== 0) {
            for (let i = 0; i < data.length; i++) {
              data[i].publishTime = dayjs(data[i].publishTime).format('YYYY-MM-DD');
              data[i].publishDay = dayjs(data[i].publishTime).format('DD');
            }
          }

          if (data.length !== 0) {
            state.newsLev1 = data.splice(0, 1)[0];
          }

          if (data.length > 1) {
            state.newsLev2 = data.splice(1, 3);
          }

          if (data.length > 4) {
            let lev3 = [];

            while (data.length > 0) {
              lev3.push(data.splice(0, 6));
            }

            state.newsLev3 = lev3;
          }
        }
      });
    };

    const goToDetail = id => {
      proxy.$router.push(`/newsDetail?id=${id}`);
    };

    const callback = val => {
      state.current = val;
    };

    const toggleSwiper = s => {
      state.swiper = s;
    };

    return {
      NewsPng,
      proxy,
      ...toRefs(state),
      callback,
      goToDetail,
      toggleSwiper
    };
  }

};