import { createRouter,createWebHistory} from 'vue-router';
import HomePage from '@/views/index';
import News from '@/views/news';
import NewsDetail from '@/views/newsDetail';
import Business from '@/views/business';
import Recruit from '@/views/recruit';
import Contact from '@/views/contact';

// 路由信息
export const routes = [
  {
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    name: 'homePage',
    component: HomePage,
    meta: { title: '首页', icon: 'house'}
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: { title: '新闻中心', icon: 'house'}
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: NewsDetail,
    meta: { title: '新闻详情', icon: 'house'}
  },
  {
    path: '/business',
    name: 'business',
    component: Business,
    meta: { title: '主营业务', icon: 'house'}
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: Recruit,
    meta: { title: '新闻详情', icon: 'house'}
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { title: '联系我们', icon: 'house'}
  },
  // { path: '/:pathMatch(.*)', redirect: '/homepage', hidden: true }
];
// Vue2.X的base选项被移除，根路径在此处配置
// const routerHistory = createWebHistory('/v3-plus')

// 导出路由
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;