import { reactive, toRefs, defineComponent, getCurrentInstance } from 'vue';
export default defineComponent({
  name: 'aHeader',
  emits: ['callback'],
  props: {
    current: {
      type: Number,
      default: 0
    }
  },

  setup(props, {
    emit
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      currentTab: props.current,
      menuVisible: false
    });

    const openMenu = () => {
      state.menuVisible = !state.menuVisible;
    };

    const handleCurrentChange = val => {
      state.currentTab = val;
      state.menuVisible = false;
      emit('callback', state.currentTab);
      const NAV = ['/', '/news', '/business', '/recruit', '/contact'];
      proxy.$router.push(NAV[val]);
    };

    return {
      proxy,
      ...toRefs(state),
      openMenu,
      handleCurrentChange
    };
  }

});